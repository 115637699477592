import { combineReducers } from 'redux';
import App from '@iso/redux/app/reducer';
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
import rootModal from '@iso/redux/rootModal/reducer';
import notification from '@iso/redux/notification/reducer';
import Register from '@iso/redux/register/reducer';
import ExhibitionCompanies from '@iso/redux/exhibitionCompanies/reducer';
import Company from '@iso/redux/company/reducer';
import ActiveIngredients from '@iso/redux/activeIngredients/reducer';
import ActiveIngredient from '@iso/redux/activeIngredient/reducer';

export default combineReducers({
  App,
  LanguageSwitcher,
  ThemeSwitcher,
  rootModal,
  notification,
  Register,
  ExhibitionCompanies,
  Company,
  ActiveIngredients,
  ActiveIngredient
});
