import {
    GET_AI_PROFILE_DATA,
    GET_AI_PROFILE_DATA_SUCCESS,
    GET_AI_VERIFIED_PRODUCERS,
    GET_AI_VERIFIED_PRODUCERS_SUCCESS,
    GET_AI_OTHER_PRODUCERS,
    GET_AI_OTHER_PRODUCERS_SUCCESS,
    SET_PAGINATION_AI_VERIFIED_PRODUCERS,
    SET_PAGINATION_AI_RELATED_PRODUCERS,
    GET_AI_RELATED_PRODUCERS,
    GET_AI_RELATED_PRODUCERS_SUCCESS,
    SET_AI_PROFILE_DATA,
    GET_AI_PROFILE_DATA_ERROR,
    CLEAR_AI_PROFILE_STATE,
    SET_PAGINATION_AI_OTHER_PRODUCERS,
    EXPORT_PRODUCERS_PROFILE_PDF,
    EXPORT_PRODUCERS_PROFILE_PDF_SUCCESS
} from "./actionTypes";

export const getAiProfileData = (product_id, params) => ({
    type: GET_AI_PROFILE_DATA,
    payload: {product_id, params}
});

export const getAiProfileDataSuccess = product => ({
    type: GET_AI_PROFILE_DATA_SUCCESS,
    payload: product,
});

export const setAiProfileData = product => ({
    type: SET_AI_PROFILE_DATA,
    payload: product,
})

export const getAiVerifiedProducers= (product_id, params) => {
    return {
        type: GET_AI_VERIFIED_PRODUCERS,
        payload: { product_id, params }
    }};

export const getAiVerifiedProducersSuccess = verified_producers => ({
    type: GET_AI_VERIFIED_PRODUCERS_SUCCESS,
    payload: verified_producers,
});

export const getAiRelatedProducers= (product_id, params) => ({
    type: GET_AI_RELATED_PRODUCERS,
    payload: { product_id, params}
});

export const getAiRelatedProducersSuccess = related_producers => ({
    type: GET_AI_RELATED_PRODUCERS_SUCCESS,
    payload: related_producers,
});

export const getAiOtherProducers= (product_id, params) => {
    return {
        type: GET_AI_OTHER_PRODUCERS,
        payload: { product_id, params }
    }};

export const getAiOtherProducersSuccess = other_producers => ({
    type: GET_AI_OTHER_PRODUCERS_SUCCESS,
    payload: other_producers,
});

export const exportProfilePdfProducers= (product_id, params) => ({
    type: EXPORT_PRODUCERS_PROFILE_PDF,
    payload: { product_id, params }
})

export const exportProfilePdfProducersSuccess = () => ({
    type: EXPORT_PRODUCERS_PROFILE_PDF_SUCCESS,
});


export const dataError = error => ({
    type: GET_AI_PROFILE_DATA_ERROR,
    payload: error,
});

export const setAiVerifiedProducersPagination = pagination => ({
    type: SET_PAGINATION_AI_VERIFIED_PRODUCERS,
    payload: pagination,
});

export const setAiRelatedProducersPagination = pagination => ({
    type: SET_PAGINATION_AI_RELATED_PRODUCERS,
    payload: pagination,
});

export const setAiOtherProducersPagination = pagination => ({
    type: SET_PAGINATION_AI_OTHER_PRODUCERS,
    payload: pagination,
});

export const clearState = (state) => ({
    type: CLEAR_AI_PROFILE_STATE,
    payload: state
});
