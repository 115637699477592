import {
    REGISTER,
    REGISTER_SUCCESS,
    REGISTER_ERROR,
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    LOGIN_VALIDATION_ERROR,
    REGISTER_VALIDATION_ERROR,
    CHECK_AUTHORIZATION,
    SET_AUTH_STATE,
    SET_AUTH_SUCCESS,
    SET_AUTH_FAIL,
    CLEAR_REGISTER_STATE
} from "./actionTypes";

export const register = (user, history) => ({
        type: REGISTER,
        payload: { user, history }
});

export const registerSuccess = response => ({
    type: REGISTER_SUCCESS,
    payload: response
});

export const registerFail = error => ({
    type: REGISTER_ERROR,
    payload: error
});

export const login = (user, history) => ({
    type: LOGIN,
    payload: { user, history }
});

export const loginSuccess = response => ({
    type: LOGIN_SUCCESS,
    payload: response
});

export const loginFail = error => ({
    type: LOGIN_ERROR,
    payload: error
});

export const loginValidationError = error => ({
    type: LOGIN_VALIDATION_ERROR,
    payload: error
});

export const registerValidationError = error => ({
    type: REGISTER_VALIDATION_ERROR,
    payload: error
});

export const checkAuthorization = () => ({
    type: CHECK_AUTHORIZATION
});

export const setAuthState = () => ({
    type: SET_AUTH_STATE
});

export const setAuthStateSuccess = (data) => ({
    type: SET_AUTH_SUCCESS,
    payload: data
});


export const setAuthStateFail = error => ({
    type: SET_AUTH_FAIL,
    payload: error
});

export const clearState = (state) => ({
    type: CLEAR_REGISTER_STATE,
    payload: state
});
