export const SEARCH_PRODUCTS = "SEARCH_PRODUCTS";
export const SEARCH_PRODUCTS_SUCCESS = "SEARCH_PRODUCTS_SUCCESS";
export const SEARCH_PRODUCTS_FAIL = "SEARCH_PRODUCTS_FAIL";

export const GET_ACTIVE_INGREDIENT_IMAGE = "GET_ACTIVE_INGREDIENT_IMAGE";
export const GET_ACTIVE_INGREDIENT_IMAGE_SUCCESS = "GET_ACTIVE_INGREDIENT_IMAGE_SUCCESS";
export const GET_ACTIVE_INGREDIENT_IMAGE_FAIL = "GET_ACTIVE_INGREDIENT_IMAGE_FAIL";

export const SET_SEARCH_QUERY = "SET_SEARCH_QUERY";

export const GET_PAGINATION_SEARCH_PRODUCTS = "GET_PAGINATION_SEARCH_PRODUCTS"
export const CLEAR_SEARCH_PRODUCTS_STATE = "CLEAR_SEARCH_PRODUCTS_STATE";
