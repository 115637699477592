import {
  GET_COMPANY,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_VERIFIED_PRODUCTS,
  GET_COMPANY_VERIFIED_PRODUCTS_SUCCESS,
  GET_PAGINATION_COMPANY_VERIFIED_PRODUCTS,

  GET_COMPANY_OTHER_PRODUCTS,
  GET_COMPANY_OTHER_PRODUCTS_SUCCESS,
  GET_PAGINATION_COMPANY_OTHER_PRODUCTS,

  GET_ACTIVE_INGREDIENT_IMAGE,
  GET_ACTIVE_INGREDIENT_IMAGE_SUCCESS,
  GET_ACTIVE_INGREDIENT_IMAGE_FAIL,
  GET_COMPANY_ERROR,
  CLEAR_COMPANY_STATE
} from "./actionTypes";

const initState = {
  company: {},
  verifiedProducts: [],
  verifiedProductsPagination: {
    current: 1,
    pageSize: 0,
    total: 0
  },
  otherPossibleProducts: [],
  otherPossibleProductsPagination: {
    current: 1,
    pageSize: 0,
    total: 0
  },
  loading: false,
  verifiedProductsLoading: false,
  otherPossibleProductsLoading: false,
  active_ingredient_image: "",
  imageLoading: false,
  success: '',
  error: '',
};


const Company = (state = initState, action) => {
  switch (action.type) {
    case GET_COMPANY:
      return {
        ...state,
        loading: true
      };

    case GET_COMPANY_SUCCESS:
      return {
        ...state,
        company: action.payload,
        loading: false
      };


    case GET_COMPANY_VERIFIED_PRODUCTS:
      return {
        ...state,
        verifiedProductsLoading: true
      };

    case GET_COMPANY_VERIFIED_PRODUCTS_SUCCESS:
      return {
        ...state,
        verifiedProducts: action.payload,
        verifiedProductsLoading: false
      };

    case GET_PAGINATION_COMPANY_VERIFIED_PRODUCTS:
      return {
        ...state,
        verifiedProductsPagination: {
          current: action.payload.current_page,
          pageSize: action.payload.per_page,
          total: action.payload.total
        },
      };

    case GET_COMPANY_OTHER_PRODUCTS:
      return {
        ...state,
        otherPossibleProductsLoading: true
      };

    case GET_COMPANY_OTHER_PRODUCTS_SUCCESS:
      return {
        ...state,
        otherPossibleProducts: action.payload,
        otherPossibleProductsLoading: false
      };

    case GET_PAGINATION_COMPANY_OTHER_PRODUCTS:
      return {
        ...state,
        otherPossibleProductsPagination: {
          current: action.payload.current_page,
          pageSize: action.payload.per_page,
          total: action.payload.total
        },
      };

    case GET_ACTIVE_INGREDIENT_IMAGE:
      return {
        ...state,
        imageLoading: true
      };
    case GET_ACTIVE_INGREDIENT_IMAGE_SUCCESS:
      return {
        ...state,
        active_ingredient_image: action.payload,
        imageLoading: false
      };
    case GET_ACTIVE_INGREDIENT_IMAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        imageLoading: false
      };

    case GET_COMPANY_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
        verifiedProductsLoading: false,
        otherPossibleProductsLoading: false
      };

    case CLEAR_COMPANY_STATE:
      return {
        ...state,
        loading: false,
        verifiedProductsLoading: false,
        otherPossibleProductsLoading: false,
        success: '',
        error: '',
      };
    default:
      return state;
  }
}

export default Company;
