import {
    SEARCH_PRODUCTS,
    SEARCH_PRODUCTS_SUCCESS,
    SEARCH_PRODUCTS_FAIL,
    CLEAR_SEARCH_PRODUCTS_STATE,
    GET_PAGINATION_SEARCH_PRODUCTS,
    SET_SEARCH_QUERY,
    GET_ACTIVE_INGREDIENT_IMAGE,
    GET_ACTIVE_INGREDIENT_IMAGE_SUCCESS,
    GET_ACTIVE_INGREDIENT_IMAGE_FAIL
} from "./actionTypes";


const initialState = {
    active_ingredients: [],
    active_ingredient_image: '',
    search_query: '',
    pagination: {
        current: 1,
        pageSize: 0,
        total: 0
    },
    loading: false,
    imageLoading: false,
    success: '',
    error: '',
};

const ActiveIngredients = (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_PRODUCTS:
            return {
                ...state,
                loading: true
            };
        case SEARCH_PRODUCTS_SUCCESS:
            return {
                ...state,
                active_ingredients: action.payload,
                loading: false
            };
        case SEARCH_PRODUCTS_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case GET_ACTIVE_INGREDIENT_IMAGE:
            return {
                ...state,
                imageLoading: true
            };
        case GET_ACTIVE_INGREDIENT_IMAGE_SUCCESS:
            return {
                ...state,
                active_ingredient_image: action.payload,
                imageLoading: false
            };
        case GET_ACTIVE_INGREDIENT_IMAGE_FAIL:
            return {
                ...state,
                error: action.payload,
                imageLoading: false
            };
        case SET_SEARCH_QUERY:
            return {
                ...state,
                search_query: action.payload
            };
        case GET_PAGINATION_SEARCH_PRODUCTS:
            return {
                ...state,
                pagination: {
                    current: action.payload.current_page,
                    pageSize: action.payload.per_page,
                    total: action.payload.total
                },
            };
        case CLEAR_SEARCH_PRODUCTS_STATE:
            return {
                ...state,
                error: '',
                success: '',
                loading: false,
                pagination: {
                    current: 1,
                    pageSize: 0,
                    total: 0
                }
            };
        default:
            return state;
    }
}

export default ActiveIngredients;
