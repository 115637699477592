import React, {lazy, Suspense} from 'react';
import {
    Route,
    Redirect,
    BrowserRouter as Router,
    Switch,
} from 'react-router-dom';

import ErrorBoundary from './ErrorBoundary';
import {PUBLIC_ROUTE} from './route.constants';
import Loader from '@iso/components/utility/loader';

const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'));

 const publicRoutes = [
     {
         path: PUBLIC_ROUTE.LANDING,
         exact: true,
         component: lazy(() => import('@iso/pages/Welcome')),
     },
     {
         path: PUBLIC_ROUTE.REGISTER,
         exact: true,
         component: lazy(() => import('@iso/pages/Register')),
     },
     {
         path: PUBLIC_ROUTE.PAGE_500,
         component: lazy(() => import('@iso/containers/Pages/500/500')),
     },
     {
         path: PUBLIC_ROUTE.PAGE_404,
         component: lazy(() => import('@iso/containers/Pages/404/404')),
     }
 ];
function PrivateRoute({children, ...rest }) {
    return (
        <Route
            {...rest}
            render={({location}) => {
                if (!localStorage.getItem('token') || !localStorage.getItem('user')) {
                    return (<Redirect
                        to={{
                            pathname: '/register',
                            state: {from: location},
                        }}
                    />)
                }
                return (
                    children
                )
                }
            }
        />
    );
}

function PublicRoutes({ children, ...rest }) {
    return (
        <Route
            {...rest}
            render={props => {
                if (localStorage.getItem('token') && localStorage.getItem('user') ) {
                    if (localStorage.getItem('active_exhibition_id') &&
                        localStorage.getItem('active_exhibition_id') !== null &&
                        localStorage.getItem('active_exhibition_id') !== "null") {
                        const active_exhibition_id = localStorage.getItem('active_exhibition_id')
                        return (<Redirect
                            to={{pathname: `/active-ingredients`, state: {from: props.location}}}
                        />)
                    }else{
                        return (<Redirect
                            to={{pathname: `/no-exhibition`, state: {from: props.location}}}
                        />)
                    }
                }
                return (
                    children
                )
            }}
        />
    );
}

export default function Routes() {
    return (
        <ErrorBoundary>
            <Suspense fallback={<Loader/>}>
                <Router>
                    <Switch>
                        {publicRoutes.map((route, index) => (
                            <PublicRoutes key={index} path={route.path} exact={route.exact}>
                                <route.component/>
                            </PublicRoutes>
                        ))}
                        <PrivateRoute path="/">
                            <Dashboard/>
                        </PrivateRoute>
                        <Route path='*' element={<h1>404</h1>}/>
                    </Switch>
                </Router>
            </Suspense>
        </ErrorBoundary>
    );
}
