import { call, all, takeEvery, put, fork } from 'redux-saga/effects';
import { createBrowserHistory } from 'history';

import { GET_COMPANY, GET_COMPANY_VERIFIED_PRODUCTS, GET_COMPANY_OTHER_PRODUCTS, GET_ACTIVE_INGREDIENT_IMAGE} from "./actionTypes";
import {
    getCompanySuccess,
    getCompanyVerifiedProductsSuccess,
    setPagination,
    dataError,
    getActiveIngredientImageSuccess,
    getActiveIngredientImageFail, setPaginationOtherProducts, getCompanyOtherProductsSuccess
} from "./actions";

import { getCompanyRequest, getCompanyVerifiedProductsRequest, getCompanyOtherProductsRequest, getActiveIngredientImageRequest } from '../../library/http/backendHelpers';


function* getCompanySaga({payload: {id}}) {
    try {
        const response = yield call(getCompanyRequest, id);
        yield put(getCompanySuccess(response.data))
    } catch (error) {
        yield put(dataError(error));
    }
}

function* getVerifiedProductsSaga({payload}){
    try {
        const response = yield call(getCompanyVerifiedProductsRequest, payload.id, payload.params);
        yield put(setPagination(response.meta))
        yield put(getCompanyVerifiedProductsSuccess(response.data))
    } catch (error) {
        yield put(dataError(error));
    }
}

function* getOtherProductsSaga({payload}){
    try {
        const response = yield call(getCompanyOtherProductsRequest, payload.id, payload.params);
        yield put(setPaginationOtherProducts(response.meta))
        yield put(getCompanyOtherProductsSuccess(response.data))
    } catch (error) {
        yield put(dataError(error));
    }
}

function* getActiveIngredientImageSaga({ payload: { product_id }}) {
    try {
        const response = yield call(getActiveIngredientImageRequest, product_id);
        yield put(getActiveIngredientImageSuccess(response.data.image));
    }catch (error) {
        yield put(getActiveIngredientImageFail(error))
    }
}

function* company() {
    yield takeEvery(GET_COMPANY, getCompanySaga);
    yield takeEvery(GET_COMPANY_VERIFIED_PRODUCTS, getVerifiedProductsSaga);
    yield takeEvery(GET_COMPANY_OTHER_PRODUCTS, getOtherProductsSaga);
    yield takeEvery(GET_ACTIVE_INGREDIENT_IMAGE, getActiveIngredientImageSaga);
}

export default company
