import {
    GET_COMPANY,
    GET_COMPANY_SUCCESS,
    GET_COMPANY_VERIFIED_PRODUCTS,
    GET_COMPANY_VERIFIED_PRODUCTS_SUCCESS,
    GET_PAGINATION_COMPANY_VERIFIED_PRODUCTS,
    GET_COMPANY_OTHER_PRODUCTS,
    GET_COMPANY_OTHER_PRODUCTS_SUCCESS,
    GET_PAGINATION_COMPANY_OTHER_PRODUCTS,
    GET_ACTIVE_INGREDIENT_IMAGE,
    GET_ACTIVE_INGREDIENT_IMAGE_SUCCESS,
    GET_ACTIVE_INGREDIENT_IMAGE_FAIL,
    GET_COMPANY_ERROR,
    CLEAR_COMPANY_STATE
} from "./actionTypes";


export const getCompany = (id) => ({
    type: GET_COMPANY,
    payload: {id}
});

export const getCompanySuccess = (company) => ({
    type: GET_COMPANY_SUCCESS,
    payload: company
});

export const getCompanyVerifiedProducts = (id, params) => ({
    type: GET_COMPANY_VERIFIED_PRODUCTS,
    payload: {id, params}
});

export const getCompanyVerifiedProductsSuccess = (products) => ({
    type: GET_COMPANY_VERIFIED_PRODUCTS_SUCCESS,
    payload: products
});

export const setPagination = pagination => ({
    type: GET_PAGINATION_COMPANY_VERIFIED_PRODUCTS,
    payload: pagination,
});

export const getCompanyOtherProducts = (id, params) => ({
    type: GET_COMPANY_OTHER_PRODUCTS,
    payload: {id, params}
});

export const getCompanyOtherProductsSuccess = (products) => ({
    type: GET_COMPANY_OTHER_PRODUCTS_SUCCESS,
    payload: products
});

export const setPaginationOtherProducts = pagination => ({
    type: GET_PAGINATION_COMPANY_OTHER_PRODUCTS,
    payload: pagination,
});

export const getActiveIngredientImage = (product_id) => ({
    type: GET_ACTIVE_INGREDIENT_IMAGE,
    payload: {product_id}
});

export const getActiveIngredientImageSuccess = base64 => ({
    type: GET_ACTIVE_INGREDIENT_IMAGE_SUCCESS,
    payload: base64,
});

export const getActiveIngredientImageFail = error => ({
    type: GET_ACTIVE_INGREDIENT_IMAGE_FAIL,
    payload: error,
});

export const dataError = error => ({
    type: GET_COMPANY_ERROR,
    payload: error,
});

export const clearState = (state) => ({
    type: CLEAR_COMPANY_STATE,
    payload: state
});
