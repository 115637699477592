import {
    REGISTER,
    REGISTER_SUCCESS,
    REGISTER_ERROR,
    REGISTER_VALIDATION_ERROR,
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    LOGIN_VALIDATION_ERROR,
    SET_AUTH_STATE,
    SET_AUTH_SUCCESS,
    CLEAR_REGISTER_STATE
} from "./actionTypes";


const initialState = {
    user: {
        first_name: '',
        last_name: '',
        email: '',
        title: '',
        company: '',
        country: '',
    },
    registerSuccess: '',
    registerError: '',
    loginSuccess: '',
    loginError: '',
    loading: false,
    routeLoading: false,
    validationError: "",
    loginValidationError: "",
    token: null,
};

const Register = (state = initialState, action) => {
    switch (action.type) {
        case REGISTER:
            return {
                ...state,
                loading: true,
            };
        case REGISTER_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.payload.visitor,
                registerSuccess: "Register Success",
                token: action.payload.token,
            };
        case REGISTER_ERROR:
            return {
                ...state,
                loading: false,
                registerError: action.payload,
            };
        case REGISTER_VALIDATION_ERROR:
            return{
                ...state,
                loading: false,
                registerSuccess: "",
                validationError: action.payload
            };
        case LOGIN:
            return {
                ...state,
                loading: true,
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.payload.visitor,
                loginSuccess: "Login Success",
                token: action.payload.token,
            };
        case LOGIN_ERROR:
            return {
                ...state,
                loading: false,
                loginError: action.payload,
            };
        case LOGIN_VALIDATION_ERROR:
            return{
                ...state,
                loading: false,
                loginSuccess: "",
                loginValidationError: action.payload
            };
        case SET_AUTH_STATE:
            return {
                ...state,
                routeLoading: true
            };
        case SET_AUTH_SUCCESS:
            return {
                ...state,
                user: action.payload.visitor,
                token: action.payload.token,
                routeLoading: false
            };
        case CLEAR_REGISTER_STATE:
            return {
                ...state,
                user: {
                    first_name: '',
                    last_name: '',
                    email: '',
                    title: '',
                    company: '',
                    country: '',
                },
                registerSuccess: '',
                registerError: '',
                loading: false,
                routeLoading: false,
                validationError: "",
                loginSuccess: '',
                loginError: '',
                loginValidationError: '',
                token: null,
            };
        default:
            return state;
    }
}

export default Register;
