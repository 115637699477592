import { call, takeEvery, put } from 'redux-saga/effects';
import {
    GET_ACTIVE_INGREDIENT_IMAGE,
    SEARCH_PRODUCTS
} from "./actionTypes";
import {
    searchActiveIngredientsSuccess,
    searchActiveIngredientsFail,
    setPagination, getActiveIngredientImageSuccess, getActiveIngredientImageFail
} from "./actions";

import {
    searchActiveIngredientRequest,
    getActiveIngredientImageRequest
} from '../../library/http/backendHelpers'


function* searchActiveIngredientSaga(params) {
    try {
        const response = yield call(searchActiveIngredientRequest, params.filters);
        yield put(setPagination(response.meta))
        yield put(searchActiveIngredientsSuccess(response.data));
    }catch (error) {
        yield put(searchActiveIngredientsFail(error))
    }
}

function* getActiveIngredientImageSaga({ payload: { product_id }}) {
    try {
        const response = yield call(getActiveIngredientImageRequest, product_id);
        yield put(getActiveIngredientImageSuccess(response.data.image));
    }catch (error) {
        yield put(getActiveIngredientImageFail(error))
    }
}

function* activeIngredientsSaga() {
    yield takeEvery(SEARCH_PRODUCTS, searchActiveIngredientSaga)
    yield takeEvery(GET_ACTIVE_INGREDIENT_IMAGE, getActiveIngredientImageSaga)
}

export default activeIngredientsSaga
