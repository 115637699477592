import { call, all, takeEvery, put, fork } from 'redux-saga/effects';
import { createBrowserHistory } from 'history';

import { GET_CURRENT_EXHIBITION, GET_EXHIBITION_COMPANIES} from "./actionTypes";
import { getCurrentExhibitionSuccess, getExhibitionCompaniesSuccess, setPagination, dataError} from "./actions";

import { getExhibition, getExhibitionCompanies } from '../../library/http/backendHelpers';


function* getExhibitionSaga({payload: {id}}) {
    try {
        const response = yield call(getExhibition, id);
        yield put(getCurrentExhibitionSuccess(response.data))
    } catch (error) {
        yield put(dataError(error));
    }
}

function* getExhibitionCompaniesSaga({payload}){
    try {
        const response = yield call(getExhibitionCompanies, payload.id, payload.params);
        yield put(setPagination(response.meta))
        yield put(getExhibitionCompaniesSuccess(response.data))
    } catch (error) {
        yield put(dataError(error));
    }
}

function* exhibitionCompanies() {
    yield takeEvery(GET_CURRENT_EXHIBITION, getExhibitionSaga);
    yield takeEvery(GET_EXHIBITION_COMPANIES, getExhibitionCompaniesSaga);
}

export default exhibitionCompanies
