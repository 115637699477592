import React, {useRef} from 'react';
import {Provider, useDispatch} from 'react-redux';
import GlobalStyles from '@iso/assets/styles/globalStyle';
import { store } from './redux/store';
import Boot from './redux/boot';
import Routes from './router';
import AppProvider from './AppProvider';
import RootModal  from '@iso/pages/Modal/RootModal';
import NotificationWindow from '@iso/components/Notifications/NotificationWindow';
import {openModalMain} from "./redux/rootModal/actions";
import LoadingBar from "react-top-loading-bar";


const App = () => {
    const ref = useRef(null)
    return (
        <Provider store={store}>
            <AppProvider>
                <>
                    <LoadingBar color='#f11946' ref={ref}/>
                    <GlobalStyles/>
                    <RootModal/>
                    <NotificationWindow/>
                    <Routes/>
                </>
            </AppProvider>
        </Provider>
    )
};
Boot()
  .then(() => App())
  .catch(error => console.error(error));

export default App;
