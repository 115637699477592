export const GET_AI_PROFILE_DATA = "GET_AI_PROFILE_DATA";
export const GET_AI_PROFILE_DATA_SUCCESS = "GET_AI_PROFILE_DATA_SUCCESS";

export const GET_AI_VERIFIED_PRODUCERS = "GET_AI_VERIFIED_PRODUCERS";
export const GET_AI_VERIFIED_PRODUCERS_SUCCESS = "GET_AI_VERIFIED_PRODUCERS_SUCCESS";

export const GET_AI_RELATED_PRODUCERS = "GET_AI_RELATED_PRODUCERS";
export const GET_AI_RELATED_PRODUCERS_SUCCESS = "GET_AI_RELATED_PRODUCERS_SUCCESS";

export const GET_AI_OTHER_PRODUCERS = "GET_AI_OTHER_PRODUCERS";
export const GET_AI_OTHER_PRODUCERS_SUCCESS = "GET_AI_OTHER_PRODUCERS_SUCCESS";

export const EXPORT_PRODUCERS_PROFILE_PDF = "EXPORT_PRODUCERS_PROFILE_PDF";
export const EXPORT_PRODUCERS_PROFILE_PDF_SUCCESS = "EXPORT_PRODUCERS_PROFILE_PDF_SUCCESS";

export const SET_PAGINATION_AI_VERIFIED_PRODUCERS = "SET_PAGINATION_COMPANY_USERS";
export const SET_PAGINATION_AI_RELATED_PRODUCERS = "SET_PAGINATION_AI_RELATED_PRODUCERS";
export const SET_PAGINATION_AI_OTHER_PRODUCERS = "SET_PAGINATION_AI_OTHER_PRODUCERS";

export const SET_AI_PROFILE_DATA = "SET_AI_PROFILE_DATA";

export const GET_AI_PROFILE_DATA_ERROR = "GET_AI_PROFILE_DATA_ERROR";
export const CLEAR_AI_PROFILE_STATE = "CLEAR_COMPANY_STATE";
