import {del, get, post, postUpload, downloadFile, put} from "./apiHelpers";
import * as url from './urlHelpers';

// Register Method
const postRegister = data => post(url.POST_REGISTER, data);
const postLogin = data => post(url.POST_LOGIN, data);
const getVisitorState = () => get(url.USER_STATE);
//exhibition
const getExhibition = id => get(`${url.EXHIBITION}/${id}`);
const getExhibitionCompanies = (id, data) => get(`${url.EXHIBITION}/${id}/companies`, {params: data});
//company
const getCompanyRequest = id => get(`${url.COMPANY}/${id}`);
const getCompanyVerifiedProductsRequest = (id, data) => get(`${url.COMPANY}/${id}/verified-products`, {params: data});
const getCompanyOtherProductsRequest = (id, data) => get(`${url.COMPANY}/${id}/other-products`, {params: data});
const getActiveIngredientImageRequest = product_id => get(`/visitors/verified-products/${product_id}/structure-image`);
//active ingredient
const searchActiveIngredientRequest = (params) => get(`${url.SEARCH_PRODUCT}`, {params: params});
const aiProducersProfileRequest = (product_id, params) => get(`${url.ACTIVE_INGREDIENT}/${product_id}`, {params: params});
const aiProducersRelatedRequest = (product_id, data) => get(`${url.ACTIVE_INGREDIENT}/${product_id}/related`, {params: data});
const aiProducersVerifiedRequest = (product_id, data) => get(`${url.ACTIVE_INGREDIENT}/${product_id}/verified`, {params: data});
const aiProducersOtherRequest = (product_id, data) => get(`${url.ACTIVE_INGREDIENT}/${product_id}/other`, {params: data});

const exportProfilePdfProducersRequest = (product_id, params, filename) => downloadFile(`${url.ACTIVE_INGREDIENT}/${product_id}/pdf`, {params: params}, filename)

export {
    postRegister,
    postLogin,
    getVisitorState,
    getExhibition,
    getExhibitionCompanies,
    getCompanyRequest,
    getCompanyVerifiedProductsRequest,
    getCompanyOtherProductsRequest,
    getActiveIngredientImageRequest,
    searchActiveIngredientRequest,
    aiProducersProfileRequest,
    aiProducersRelatedRequest,
    aiProducersVerifiedRequest,
    aiProducersOtherRequest,
    exportProfilePdfProducersRequest
}
