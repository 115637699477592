import {
    GET_CURRENT_EXHIBITION,
    GET_CURRENT_EXHIBITION_SUCCESS,
    GET_EXHIBITION_COMPANIES,
    GET_EXHIBITION_COMPANIES_SUCCESS,
    GET_PAGINATION_EXHIBITION_COMPANIES,

    GET_EXHIBITION_COMPANIES_ERROR,
    CLEAR_EXHIBITION_COMPANIES_STATE
} from "./actionTypes";

const initState = {
  currentExhibition: {},
  exhibitionCompanies: [],
  pagination: {
    current: 1,
    pageSize: 0,
    total: 0
  },
  loading: false,
  exhibitionLoading: false,
  success: '',
  error: '',
};


const ExhibitionCompanies = (state = initState, action) => {
  switch (action.type) {
    case GET_CURRENT_EXHIBITION:
      return {
        ...state,
        exhibitionLoading: true
      };

    case GET_CURRENT_EXHIBITION_SUCCESS:
      return {
        ...state,
        currentExhibition: action.payload,
        exhibitionLoading: false
      };


    case GET_EXHIBITION_COMPANIES:
      return {
        ...state,
        loading: true
      };

    case GET_EXHIBITION_COMPANIES_SUCCESS:
      return {
        ...state,
        exhibitionCompanies: action.payload,
        loading: false
      };

    case GET_PAGINATION_EXHIBITION_COMPANIES:
      return {
        ...state,
        pagination: {
          current: action.payload.current_page,
          pageSize: action.payload.per_page,
          total: action.payload.total
        },
      };

    case GET_EXHIBITION_COMPANIES_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
        exhibitionLoading: false
      };

    case CLEAR_EXHIBITION_COMPANIES_STATE:
      return {
        ...state,
        loading: false,
        exhibitionLoading: false,
        success: '',
        error: '',
      };
    default:
      return state;
  }
}

export default ExhibitionCompanies;
