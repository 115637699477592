import {
    GET_CURRENT_EXHIBITION,
    GET_CURRENT_EXHIBITION_SUCCESS,
    GET_EXHIBITION_COMPANIES,
    GET_EXHIBITION_COMPANIES_SUCCESS,
    GET_PAGINATION_EXHIBITION_COMPANIES,
    GET_EXHIBITION_COMPANIES_ERROR,
    CLEAR_EXHIBITION_COMPANIES_STATE
} from "./actionTypes";


export const getCurrentExhibition = (id) => ({
    type: GET_CURRENT_EXHIBITION,
    payload: {id}
});

export const getCurrentExhibitionSuccess = (currentExhibition) => ({
    type: GET_CURRENT_EXHIBITION_SUCCESS,
    payload: currentExhibition
});

export const getExhibitionCompanies = (id, params) => ({
    type: GET_EXHIBITION_COMPANIES,
    payload: {id, params}
});

export const getExhibitionCompaniesSuccess = (companies) => ({
    type: GET_EXHIBITION_COMPANIES_SUCCESS,
    payload: companies
});

export const setPagination = pagination => ({
    type: GET_PAGINATION_EXHIBITION_COMPANIES,
    payload: pagination,
});

export const dataError = error => ({
    type: GET_EXHIBITION_COMPANIES_ERROR,
    payload: error,
});

export const clearState = (state) => ({
    type: CLEAR_EXHIBITION_COMPANIES_STATE,
    payload: state
});
