import { all } from 'redux-saga/effects';
import registerSaga from '@iso/redux/register/saga'
import exhibitionCompanies from '@iso/redux/exhibitionCompanies/saga'
import company from '@iso/redux/company/saga'
import activeIngredientsSaga from '@iso/redux/activeIngredients/saga';
import activeIngredientSaga from '@iso/redux/activeIngredient/saga';

export default function* rootSaga(getState) {
  yield all([
      registerSaga(),
      exhibitionCompanies(),
      company(),
      activeIngredientsSaga(),
      activeIngredientSaga()
  ]);
}
