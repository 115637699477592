import React from "react";
import {useSelector} from "react-redux";
import {Skeleton, Spin, Typography} from "antd";

const ActiveIngredientProfileModal = () => {
    const {product, loadingProfile, verified_pagination, loadingVerified, loadingOther, other_pagination} = useSelector(state => state.ActiveIngredient)
    const {currentExhibition, exhibitionLoading} = useSelector(state => state.ExhibitionCompanies)
    const {Text, Paragraph} = Typography;
    return (
        <>
            <Spin spinning={loadingProfile || loadingVerified || loadingOther}>
                {(loadingProfile || loadingVerified || loadingOther) ? <Skeleton active/> :
                    <Paragraph>
                        <Text style={{fontSize: "17px"}}>
                            There are {product?.count_verified + product?.count_other} producers of {product?.name} worldwide.<br/>
                            {verified_pagination?.total + other_pagination?.total} are exhibiting at {currentExhibition?.exhibition_name}.<br/>
                            Please check OK below to see the producers who are exhibiting.<br/>
                            For more information on all the worldwide producers
                            please contact Becker Data at sales@beckerdata.com or <a href="mailto:sales@beckerdata.com" target='_blank'>click here</a> to automatically send
                            a message to them telling them that you want more information about their
                            WAPD directory
                        </Text>
                    </Paragraph>
                }
            </Spin>
        </>
    );
}

export default ActiveIngredientProfileModal
