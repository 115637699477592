import React from 'react';
import { Modal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import {closeModalMain} from '@iso/redux/rootModal/actions';
import SynonymModal from "../Company/Components/SynonymModal";
import ProductChemicalStructureModal from "../Company/Components/ProductChemicalStructureModal";
import ActiveIngredientProfileModal from "../ActiveIngredientProfile/Components/ActiveIngredientProfileModal";

const MODAL_COMPONENTS = {
    PRODUCTS_SYNONYMS_MODAL: SynonymModal,
    CHEMICAL_STRUCTURE: ProductChemicalStructureModal,
    ACTIVE_INGREDIENT_PROFILE_MODAL: ActiveIngredientProfileModal,
};


const RootModal = () => {

    const dispatch = useDispatch();
    const modalVisibility = useSelector((state) => state.rootModal.modalVisibility);
    const modalProps = useSelector((state) => state.rootModal.modalProps);
    const modalType = useSelector((state) => state.rootModal.modalType);
    const modalBodyProps = useSelector((state) => state.rootModal.modalBodyProps);

    const SpecificModal = MODAL_COMPONENTS[modalType];
    return(
        <Modal
            visible={modalVisibility}
            onCancel={() => dispatch(closeModalMain())}
            {...modalProps}
        >
            <SpecificModal  {...modalBodyProps} onCancel={() => dispatch(closeModalMain())} />
        </Modal>
    );

}


export default RootModal;
