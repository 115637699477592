export const GET_COMPANY = "GET_COMPANY";
export const GET_COMPANY_SUCCESS = "GET_COMPANY_SUCCESS";

export const GET_COMPANY_VERIFIED_PRODUCTS = "GET_COMPANY_VERIFIED_PRODUCTS";
export const GET_COMPANY_VERIFIED_PRODUCTS_SUCCESS = "GET_COMPANY_VERIFIED_PRODUCTS_SUCCESS";
export const GET_PAGINATION_COMPANY_VERIFIED_PRODUCTS = "GET_PAGINATION_COMPANY_VERIFIED_PRODUCTS";

export const GET_COMPANY_OTHER_PRODUCTS = "GET_COMPANY_OTHER_PRODUCTS";
export const GET_COMPANY_OTHER_PRODUCTS_SUCCESS = "GET_COMPANY_OTHER_PRODUCTS_SUCCESS";
export const GET_PAGINATION_COMPANY_OTHER_PRODUCTS = "GET_PAGINATION_COMPANY_OTHER_PRODUCTS";


export const GET_ACTIVE_INGREDIENT_IMAGE = "GET_ACTIVE_INGREDIENT_IMAGE"
export const GET_ACTIVE_INGREDIENT_IMAGE_SUCCESS = "GET_ACTIVE_INGREDIENT_IMAGE_SUCCESS"
export const GET_ACTIVE_INGREDIENT_IMAGE_FAIL = "GET_ACTIVE_INGREDIENT_IMAGE_FAIL"

export const GET_COMPANY_ERROR = "GET_COMPANY_ERROR";
export const CLEAR_COMPANY_STATE = "CLEAR_COMPANY_STATE";
