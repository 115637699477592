export const REGISTER = "REGISTER";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_ERROR = "REGISTER_ERROR"
export const REGISTER_VALIDATION_ERROR = "REGISTER_VALIDATION_ERROR"
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR"
export const LOGIN_VALIDATION_ERROR = "LOGIN_VALIDATION_ERROR"

export const CHECK_AUTHORIZATION = 'CHECK_AUTHORIZATION';

export const SET_AUTH_STATE = "SET_AUTH_STATE";
export const SET_AUTH_SUCCESS = "SET_AUTH_SUCCESS";
export const SET_AUTH_FAIL = "SET_AUTH_FAIL";

export const CLEAR_REGISTER_STATE = "CLEAR_REGISTER_STATE";
