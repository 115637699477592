import { call, takeEvery, put } from 'redux-saga/effects';
import {
    GET_AI_VERIFIED_PRODUCERS,
    GET_AI_RELATED_PRODUCERS, GET_AI_OTHER_PRODUCERS, GET_AI_PROFILE_DATA,EXPORT_PRODUCERS_PROFILE_PDF
} from "./actionTypes";
import moment from "moment";

import {
    getAiVerifiedProducersSuccess,
    getAiRelatedProducersSuccess,
    setAiVerifiedProducersPagination,
    setAiRelatedProducersPagination,
    dataError,
    getAiOtherProducersSuccess,
    setAiOtherProducersPagination,
    getAiProfileDataSuccess, exportProfilePdfProducersSuccess,
} from "./actions";

import {
    aiProducersProfileRequest,
    aiProducersRelatedRequest,
    aiProducersVerifiedRequest,
    aiProducersOtherRequest,
    exportProfilePdfProducersRequest
} from '../../library/http/backendHelpers'

function* getAiProfileDataSaga({ payload: { product_id, params }}) {
    try {
        const response = yield call(aiProducersProfileRequest, product_id, params);
        yield put(getAiProfileDataSuccess(response.data));
    } catch (error) {
        yield put(dataError(error))
    }
}

function* getAiRelatedProducersSaga({ payload: { product_id, params}}) {
    try {
        const response = yield call(aiProducersRelatedRequest, product_id, params);
        yield put(setAiRelatedProducersPagination(response.meta));
        yield put(getAiRelatedProducersSuccess(response.data));
    }catch (error) {
        yield put(dataError(error))
    }
}

function* getAiVerifiedProducersSaga({ payload: { product_id, params }}) {
    try {
        const response = yield call(aiProducersVerifiedRequest, product_id, params);
        yield put(setAiVerifiedProducersPagination(response.meta));
        yield put(getAiVerifiedProducersSuccess(response.data));
    }catch (error) {
        yield put(dataError(error))
    }
}

function* getAiOtherProducersSaga({ payload: { product_id, params}}) {
    try {
        const response = yield call(aiProducersOtherRequest, product_id, params);
        yield put(setAiOtherProducersPagination(response.meta));
        yield put(getAiOtherProducersSuccess(response.data));
    }catch (error) {
        yield put(dataError(error))
    }
}

function* exportProfilePdfProducersSaga({ payload: { product_id, params}}) {
    try {
        const filename  = 'api-producers-company-report-'+ moment().format('Y-m-d HH:mm:ss') + '.pdf';
        const response = yield call(exportProfilePdfProducersRequest, product_id, params, filename);
        yield put(exportProfilePdfProducersSuccess());
    }catch (error) {
        yield put(dataError(error))
    }
}

function* activeIngredientSaga() {
    yield takeEvery(GET_AI_PROFILE_DATA, getAiProfileDataSaga)
    yield takeEvery(GET_AI_RELATED_PRODUCERS, getAiRelatedProducersSaga)
    yield takeEvery(GET_AI_VERIFIED_PRODUCERS, getAiVerifiedProducersSaga)
    yield takeEvery(GET_AI_OTHER_PRODUCERS, getAiOtherProducersSaga)
    yield takeEvery(EXPORT_PRODUCERS_PROFILE_PDF, exportProfilePdfProducersSaga)
}

export default activeIngredientSaga
