import { call, takeEvery, put } from 'redux-saga/effects';
import { getToken, clearToken } from '../../library/helpers/utility';
import {
    REGISTER,
    LOGIN,
    SET_AUTH_STATE
} from "./actionTypes";

import {
    registerSuccess,
    registerFail,
    setAuthStateSuccess,
    registerValidationError,
    loginSuccess,
    loginFail,
    loginValidationError
} from "./actions";

import {
    postRegister,
    getVisitorState,
    postLogin
} from '../../library/http/backendHelpers'


function* registerRequestSaga({ payload: { user, history }}) {
    try {
        const response = yield call(postRegister, user);
        yield put(registerSuccess(response));
        setLoginLocalStorage(response)
        redirect(response, history)
    }catch (error) {
        if(error.code === 422) {
            yield put(registerValidationError(error));
        }else{
            yield put(registerFail(error));
        }
    }
}

function* loginRequestSaga({ payload: { user, history }}) {
    try {
        const response = yield call(postLogin, user);
        yield put(loginSuccess(response));
        setLoginLocalStorage(response)
        redirect(response, history)
    }catch (error) {
        if(error.code === 422) {
            yield put(loginValidationError(error));
        }else{
            yield put(loginFail(error));
        }
    }
}

function* checkAuthorization() {
    try {
        if (localStorage.getItem('token')) {
            const response = yield call(getVisitorState);
            setUserStateLocalStorage(response);
        }
        const token = getToken().get('token');
        const user = getToken().get('user');
        if (token && user) {
            yield put(setAuthStateSuccess({
                token: token,
                user: JSON.parse(user),
            }))
        } else {
            yield put(setAuthStateSuccess({
                token: null,
                user: null
            }));
        }
    } catch (e) {
        resetLoginLocalStorage();
    }
}

function setLoginLocalStorage(response) {
    localStorage.setItem("token", response.token);
    localStorage.setItem("user", JSON.stringify(response.visitor));
    localStorage.setItem("active_exhibition_id", JSON.stringify(response.active_exhibition_id));
}

function setUserStateLocalStorage(response) {
    localStorage.setItem("user", JSON.stringify(response.visitor));
    localStorage.setItem("active_exhibition_id", JSON.stringify(response.active_exhibition_id));
}

function resetLoginLocalStorage() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("active_exhibition_id");
}

const redirect = (response, history) => {
    if(response.active_exhibition_id && response.active_exhibition_id !== null && response.active_exhibition_id !== "null") {
        history.push(`/active-ingredients`)
    }else{
        history.push(`/no-exhibition`)
    }
}


function* registerSaga() {
    yield takeEvery(REGISTER, registerRequestSaga)
    yield takeEvery(LOGIN, loginRequestSaga)
    yield takeEvery(SET_AUTH_STATE, checkAuthorization);
}

export default registerSaga
