export const GET_CURRENT_EXHIBITION = "GET_CURRENT_EXHIBITION";
export const GET_CURRENT_EXHIBITION_SUCCESS = "GET_CURRENT_EXHIBITION_SUCCESS";

export const GET_EXHIBITION_COMPANIES = "GET_EXHIBITION_COMPANIES";
export const GET_EXHIBITION_COMPANIES_SUCCESS = "GET_EXHIBITION_COMPANIES_SUCCESS";
export const GET_PAGINATION_EXHIBITION_COMPANIES = "GET_PAGINATION_EXHIBITION_COMPANIES";


export const GET_EXHIBITION_COMPANIES_ERROR = "GET_EXHIBITION_COMPANIES_DATA_ERROR";
export const CLEAR_EXHIBITION_COMPANIES_STATE = "CLEAR_EXHIBITION_COMPANIES_STATE";
